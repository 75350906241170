// Импорт необходимых зависимостей
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// Импорт компонентов MUI

// Импорт хранилища и основного компонента приложения
import App from "./App";
import { store, persistor } from "./store/store";

// Импорт настроек интернационализации
import "./utils/i18n/index";

// Импорт глобальных стилей
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";

// Создание темы MUI
const theme = createTheme();

// Создание корневого элемента для рендеринга
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// Рендеринг приложения с обертками для Redux, MUI и PersistGate
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
