import { Tooltip, TooltipProps } from "@mui/material";
import classNames from "classnames";
import { CSSProperties, ReactNode } from "react";
import Icons from "react-icons/fi";

import SvgIcon from "../SvgIcon";

import styles from "./index.module.scss";

interface ISvgButtonProps {
  icon: keyof typeof Icons;
  className?: string;
  iconClassName?: string;
  onClick: () => void;
  style?: CSSProperties;
  isDisabled?: boolean;
  tooltip?: string;
}

function SvgButton({
  icon,
  className,
  iconClassName,
  onClick,
  style,
  isDisabled,
  tooltip,
}: ISvgButtonProps) {
  const ComponentWrapper = tooltip
    ? (props: TooltipProps) => (
        <Tooltip sx={{ position: "relative" }} {...props} />
      )
    : ({ children }: { children: ReactNode }) => (
        <div className={styles.button_wrapper}>{children}</div>
      );

  return (
    <ComponentWrapper title={tooltip} placement="top">
      <button
        style={style}
        type="button"
        tabIndex={-1}
        aria-label={icon}
        onClick={onClick}
        disabled={isDisabled}
        className={classNames(styles.button, className, {
          [styles.button_disabled]: isDisabled,
        })}>
        <SvgIcon
          type={icon}
          className={classNames(styles.icon, iconClassName)}
        />
      </button>
    </ComponentWrapper>
  );
}

SvgButton.defaultProps = {
  className: "",
};

export default SvgButton;
